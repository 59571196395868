<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */
const drawerOpen = ref(false)
const styleDrawer = computed(() => {
    if (drawerOpen.value) {
        return "open-drawer"
    }
    return "close-drawer"
})

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    document.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
            document
                .querySelector(".drawer-container")
                ?.classList.remove("open-drawer")
            document
                .querySelector(".drawer-container")
                ?.classList.add("close-drawer")

            closeDrawer()
        }
    })
})

onBeforeUnmount(() => {
    document.removeEventListener("keydown", function (event) {
        if (event.key === "Escape") {
            document
                .querySelector(".drawer-container")
                ?.classList.remove("open-drawer")
            document
                .querySelector(".drawer-container")
                ?.classList.add("close-drawer")

            closeDrawer()
        }
    })
})

watch(styleDrawer, (newValue) => {
    if (newValue === "close-drawer") {
        drawerOpen.value = false
    }
})

/**
 *
 *  Functions section
 *
 *
 */

const toggleDrawer = () => {
    drawerOpen.value = !drawerOpen.value
}

const closeDrawer = () => {
    if (drawerOpen.value) {
        toggleDrawer()
    }
}

defineExpose({
    toggleDrawer,
})
</script>

<template>
    <section
        :class="`drawer-container-wrapper ${drawerOpen ? '!tw-z-[999]' : '!-tw-z-1 !tw-relative'}`"
    >
        <div :class="`drawer-container ${styleDrawer}`">
            <slot></slot>
        </div>
        <div
            :class="`tw-absolute tw-inset-0 tw-bg-customGray-700 tw-opacity-65 ${drawerOpen ? 'tw-block' : 'tw-hidden'}`"
            @click="closeDrawer"
        ></div>
    </section>
</template>

<style>
.drawer-container-wrapper {
    @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-h-full tw-overflow-hidden;
}
.drawer-container {
    @apply tw-fixed tw-z-[9999] tw-top-0 tw-left-0 tw-right-1/4 tw-max-w-[320px] tw-bottom-0 tw-h-full tw-overflow-y-auto tw-overflow-x-hidden tw-bg-white tw-shadow-2xl tw-shadow-black tw-transition-all tw-duration-300;
}

.open-drawer {
    @apply tw-left-0;
}

.close-drawer {
    @apply -tw-left-full;
}
</style>
